.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F9F3EE;
  min-height: 100vh;
  position: relative;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.contendant {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.separator {
  width: 1px;
  height: 100%;
  background: black;
}

.image {
  height: 420px;
  width: 420px;  
  border-radius: 20px;
  overflow: hidden;
}

.playButton {
  margin: 0;
  height: 300px;
  width: 300px;
  color: #F9F3EE;
  text-align: center;
  line-height: 300px;
  font-weight: bold;
  border-radius: 50%;
  background: black;
  font-size: 60px;
  transform: scale(1);
  transition: 0.4s;
}

.playButton:hover {
  cursor: pointer;
  font-size: 20px;
  transform: scale(1.4);
}

.enhance {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-13%, -13%);
  border: 5px solid #1FD760;
  border-radius: 200px;
  background: #1FD760;
  width: 400px;
  height: 400px;
  animation: rotate-on-itself 5s linear infinite;
  transition: 0.4s;
}

@keyframes rotate-on-itself {
  0% {
    transform: translate(-13%, -13%) rotate(0deg);
  }
  100% {
    transform: translate(-13%, -13%) rotate(360deg);
  }
}

.enhance:hover {
  cursor: pointer;
  background: pink;
  border: 5px solid pink;

}

.date {
  color: rgba(0,0,0,0.3);
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 10px;
}

.playButton:active {
  transform: scale(0.9);
}

.flamme {
  margin-left: -8px;
}

.qText {
  margin: 3px;
  margin-bottom: 15px;
  text-align: center;
}

.qText2 {
  margin: 3px;
  margin-bottom: 6px;
  text-align: center;
}

.midContent {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 20px;

}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  color: #F9F3EE;
}

.button {
  width: 210px;
  margin: 0;
  text-align: center;
  border-radius: 10px; 
  padding: 11px 0;
  background: #F9F3EE;
  transform: scale(1);
  transition: 0.4s;
  font-size: 17px;
  font-weight: bold;
}

.button:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.buttonP {
  background: black;
  color: white;
}

.buttonM {
  background: white;
  color: black;
}

.name {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}

.score {
  transition: 0.4s;
  font-size: 50px;
  font-weight: bold;
  margin: 0;
}

.currentScore {
  margin: 0px;
  margin-bottom: 30px;
  margin-top: -10px;
  font-size: 60px;
  font-weight: "bold";
  margin-left: 14px;
}

@media only screen and (max-width: 800px) {

  .contendant {
    flex: 0;
  }


  .container {
    justify-content: flex-start;
  }

  .fullHeight {
    justify-content: center !important;
  }

  .image {
    width: 100%;
    height: 200px;
    border-radius: 0px;
    background-position: center;

  }

  .flamme {
    margin-left: -8px;
  }

  .currentScore {
    margin-bottom: 13px;
    margin-top: -2px;
    font-size: 46px;
  }

  .contendant {
    width: 100%;
  }

  .homepage {
    transform: scale(0.8);
  }

  .midContent {
    padding: 0;
    margin-top: 13px;
    margin-bottom: 18px;
  }

  .qText {
    font-size: 20px;
    padding: 0 20px;
  }

  .qText2 {
    opacity: 0;
    height: 2px;
  }
}


@media only screen and (max-width: 1180px) {

  .container {
    flex-direction: column;
  }

}